import { UPDATE_COLUMNS } from '../constants/proposal-columns'

const proposal:any = {
  columns:  [
    {
      key: 2,
      title: "name",
      selected: true,
      disabled: false,
      dataIndex: "supplier_alias",
      width: 150,
    },
    {
      key: 3,
      title: "environment",
      selected: true,
      disabled: false,
      dataIndex: "geopath_clasification",
      width: 120,
    },
    {
      key: 4,
      title: "material",
      selected: true,
      disabled: false,
      dataIndex: "material",
      width: 120,
    },
    {
      key: 5,
      title: "type",
      selected: true,
      disabled: false,
      dataIndex: "asset_type",
      width: 120,
    },
    {
      key: 6,
      title: "location",
      selected: true,
      disabled: false,
      dataIndex: "address",
      width: 200,
    },
    {
      key: 7,
      title: "city",
      selected: false,
      disabled: false,
      dataIndex: "city",
      width: 120,
    },
    {
      key: 8,
      title: "price",
      selected: false,
      disabled: false,
      dataIndex: "price",
      width: 120,
    },
    {
      key: 9,
      title: "ots",
      selected: false,
      disabled: false,
      dataIndex: "ots",
      width: 120,
    },
    {
      key: 10,
      title: "hiring",
      selected: false,
      disabled: false,
      dataIndex: "hiring_period",
      width: 120,
    },
    {
      key: 11,
      title: "geolocation",
      selected: false,
      disabled: false,
      dataIndex: "location",
      width: 120,
    },
    {
      key: 12,
      title: "measures",
      selected: false,
      disabled: false,
      dataIndex: "measure_unit",
      width: 120,
    }
  ]
}

// eslint-disable-next-line
export default (state = proposal, action: any = {}) => {
  switch(action.type) {
    case UPDATE_COLUMNS:
      return {
        ...action.payload
      }
    default:
      return state
  }
}
